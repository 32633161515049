import React from "react";

import ViewMoreArticlesFunc from "./viewMoreArticles";

const RichTextOutput = ({ title, content, viewMoreArticles }) => {
  return (
    <section>
        <div className="p-8 lg:py-16 lg:px-48">
            <h2 className="text-2xl font-bold mb-3">{title}</h2>
            <div className="" dangerouslySetInnerHTML={{ __html: content }}></div>
        </div>

        <ViewMoreArticlesFunc viewMoreArticles={viewMoreArticles}/>
    </section>
  );
};

export default RichTextOutput;
