import React, { useState } from "react";

import { Link } from "react-router-dom";

// Media
import Skytte from "../media/skytte.jpg";
import Karta from "../media/karta.jpg";

function Header({ data }) {
  const pages =
    data?.categories?.find((category) => category.name === "Sidor")?.items ||
    [];
  const links =
    data?.categories?.find((category) => category.name === "Länkar")?.items ||
    [];

  const [isNavVisible, setNavVisible] = useState(true);

  const toggleNav = () => {
    setNavVisible((prevState) => !prevState);
  };
  return (
    <header>
      <section className="h-[20vh] lg:h-[33vh] flex justify-between items-center">
        <img className="h-1/2 lg:h-full" src={Skytte} alt="Skytte" />
        <h1 className="text-lg lg:text-3xl font-bold mb-4 mx-4">
          Föreningen Orienteringsskytte
        </h1>
        <img
            className="h-1/2 lg:h-full lg:mr-10 border-r-4"
            src={Karta}
            alt="Karta"
            style={{
              transformStyle: "preserve-3d",
              transform:
                "rotateX(70deg) rotateZ(-60deg)",
              perspective: "3000px",
              boxShadow: "-80px 60px 15px 5px rgba(0,0,0,0.4)",
            }}
          />
      </section>

      <div
        className="fixed left-4 top-4 space-y-2 z-10 md:hidden"
        onClick={toggleNav}
      >
        <div className="w-8 h-0.5 bg-gray-600"></div>
        <div className="w-8 h-0.5 bg-gray-600"></div>
        <div className="w-8 h-0.5 bg-gray-600"></div>
      </div>

      <nav
        className={`flex gap-4 flex-col fixed left-0 top-0 bg-black h-screen w-[50vw] items-center p-5 transition duration-500 ease-in-out
            ${isNavVisible ? "-translate-x-full" : "translate-x-0"}
            md:flex-row md:justify-center md:p-1 md:bg-gray-900 md:h-fit md:static md:w-full md:transform-none`}
      >
        <Link className="px-4 py-2 text-white" to="/nyheter">
          Nyheter
        </Link>

        {pages.map((item) => (
          <Link
            className="px-4 py-2 text-white"
            to={`/${item.link}`}
            key={item.name}
          >
            {item.name}
          </Link>
        ))}

        {links.map((item) => (
          <Link className="px-4 py-2 text-white" to={item.link} key={item.name}>
            {item.name}
          </Link>
        ))}

        <Link className="px-4 py-2 text-white" to="/foreningsinfo">
          Föreningsinfo
        </Link>
      </nav>
    </header>
  );
}

export default Header;
