function Info() {
    return (
      <section className="p-8 lg:py-16 lg:px-48">
        <div className="flex flex-col items-center justify-center">
          <h2 className="text-2xl font-bold mb-6">Föreningsinfo</h2>
          <table className="w-full bg-gray-100 border border-gray-300 shadow-lg rounded overflow-hidden">
            <tbody>
              <tr className="border-b">
                <th className="p-4 text-left bg-gray-200 text-gray-700">Bildad</th>
                <td className="p-4">2013-01-29</td>
              </tr>
              <tr className="border-b">
                <th className="p-4 text-left bg-gray-200 text-gray-700">Föreningsnummer</th>
                <td className="p-4">50029-44</td>
              </tr>
              <tr className="border-b">
                <th className="p-4 text-left bg-gray-200 text-gray-700">Postadress</th>
                <td className="p-4">
                  Per Andén, Vivalliusgatan 41 br
                  <br />
                  75442 Uppsala
                </td>
              </tr>
              <tr className="border-b">
                <th className="p-4 text-left bg-gray-200 text-gray-700">Telefon</th>
                <td className="p-4">+46733684107</td>
              </tr>
              <tr className="border-b">
                <th className="p-4 text-left bg-gray-200 text-gray-700">E-post</th>
                <td className="p-4">
                  <a href="mailto:jarp_ann@hotmail.com" className="text-blue-500 hover:underline">jarp_ann@hotmail.com</a>
                </td>
              </tr>
              <tr className="border-b">
                <th className="p-4 text-left bg-gray-200 text-gray-700">Bankgiro</th>
                <td className="p-4">891-9821</td>
              </tr>
              <tr>
                <th className="p-4 text-left bg-gray-200 text-gray-700">Organisationummer</th>
                <td className="p-4">802472-1097</td>
              </tr>
            </tbody>
          </table>
        </div>
      </section>
    );
  }
  
  export default Info;
  