function Footer() {
  return (
    <footer className="bg-gray-900 text-white p-6">
      <div className="container mx-auto flex flex-col md:flex-row justify-center">
        <div className="mb-6 md:mb-0 md:mr-6">
          <h4 className="text-lg font-semibold mb-2">Postadress:</h4>
          <p className="mb-1">Föreningen Orienteringsskytte - Mångkamp</p>
          <p className="mb-1">Per Andén, Vivalliusgatan 41</p>
          <p className="mb-1">75442 Uppsala</p>
        </div>

        <div>
          <h4 className="text-lg font-semibold mb-2">Kontakt</h4>
          <p className="mb-1">Tel: +46733684107</p>
          <p className="mb-1">
            E-post:{" "}
            <a href="mailto:jarp_ann@hotmail.com" className="text-white">
              jarp_ann@hotmail.com
            </a>
          </p>
        </div>
      </div>

      <div className="border-t border-white mt-6 py-4">
        <div className="container mx-auto text-center">
          <p className="mb-4">2023 &copy; Föreningen Orienteringsskytte</p>
          <p className="mb-0">
            Hemsidan skapad av{" "}
            <a href="https://vbwebb.se" className="text-white">
              Victor Berglund
            </a>
          </p>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
