import React, { useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";

import Header from "./components/header.js";
import Footer from "./components/footer.js";
import News from "./components/news.js";
import Pages from "./components/pages.js";
import Info from "./components/info.js";
import Article from "./components/article.js";

function App() {
  const [website, setWebsite] = useState("");

  useEffect(() => {
    const getWebsite = async () => {
      const websiteFromServer = await fetchWebsite();
      setWebsite(websiteFromServer);
    };

    getWebsite();
  }, []);

  const fetchWebsite = async () => {
    const res = await fetch(
      "https://api.vbwebb.se/api/websites/64898745cd377e7613679ec5"
    );
    const data = await res.json();
    return data;
  };

  const createViewMoreArticles = (articles, article) => {
    const viewMoreArticles = [];

    for (let i = 0; i < articles.length; i++) {
      if (viewMoreArticles.length <= 3) {
        if (articles[i].title !== article.title) {
          viewMoreArticles.push(articles[i]);
        }
      }
    }

    return viewMoreArticles;
  };

  if (!website) {
    return <div className="p-16">Laddar in...</div>;
  }

  return (
    <Router>
      <Header data={website} />
      <Routes>
        <Route exact path="/nyheter" element={<News data={website.categories} />} />
        <Route exact path="/foreningsinfo" element={<Info />} />

        {website.categories &&
          website.categories.map((c) => {
            if (c.name === "Nyheter") {
              return c.items.map((i) => (
                <Route
                  key={i.link}
                  exact
                  path={`/nyheter/${i.link}`}
                  element={
                    <Article
                      title={i.name}
                      content={i.content}
                      viewMoreArticles={createViewMoreArticles(c.items, i)}
                    />
                  }
                />
              ));
            } else if (c.name === "Sidor") {
              return c.items.map((i) => (
                <Route
                  key={i.link}
                  exact
                  path={`/${i.link}`}
                  element={<Pages title={i.name} content={i.content} />}
                />
              ));
            }
            return null;
          })}

        <Route path="/" element={<Navigate to="/nyheter" replace />} />
      </Routes>
      <Footer />
    </Router>
  );
}

export default App;
