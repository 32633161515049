function Pages ({ title, content }){
    return(
        <section>
            <div className="p-8 lg:py-16 lg:px-48">
                <h2 className="text-2xl font-bold">{title}</h2>
                <div className="" dangerouslySetInnerHTML={{ __html: content }}></div>
            </div>
        </section>
    )
}

export default Pages